/** @format */
/*
  seeing window focus outline when clicking ion-menu ?
  :focus { outline: none; }
*/

// http://ionicframework.com/docs/theming/
/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/** Basic CSS for Ionic Apps */
// @import '~@ionic/angular/css/core.css';
// @import '~@ionic/angular/css/normalize.css';
// @import '~@ionic/angular/css/structure.css';
// @import '~@ionic/angular/css/typography.css';
// @import '~@ionic/angular/css/display.css';
/** Optional CSS utilities that can be commented out */
// @import '~@ionic/angular/css/padding.css';
// @import '~@ionic/angular/css/float-elements.css';
// @import '~@ionic/angular/css/text-alignment.css';
// @import '~@ionic/angular/css/text-transformation.css';
// @import '~@ionic/angular/css/flex-utils.css';

/* @import '@ionic/angular/css/palettes/dark.always.css'; */
// @import "@ionic/angular/css/palettes/dark.class.css";
// @import "@ionic/angular/css/palettes/dark.system.css";

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';
$container-max-widths: (
  xl: 1400px,
);
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/utilities/display';
// @import './theme/variables.scss';
@import 'node_modules/ngx-float-ui/css/theme-dark.css';

$color-title: var(--color-title, #e9eade); // why is this not in scope for ion-select? setting var to var?
$color-text: var(--color-text, #8b897f);

* {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

// https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
input:-webkit-autofill { 
  -webkit-background-clip: text;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--color-text);
    // transition: background-color 5000s ease-in-out 0s; // being overwritten
    background-color: var(--color-background);
    box-shadow: inset 0 0 20px 20px var(--color-background);
}
.light {
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      -webkit-background-clip: text;
      -webkit-text-fill-color: var(--ion-color-dark);
      background-color: var(--color-split-layout-background);
      box-shadow: inset 0 0 20px 20px var(--color-split-layout-background);
  }

  .thumbnail-poster__play {
    color: var(--ion-color-light) !important;
  }
}

.page {
  min-height: 300px;
}

h1 {
  font: normal normal 300 32px/40px var(--roboto, sans-serif);
}

h2 {
  font: normal normal bold 24px/30px var(--roboto, sans-serif);
  margin: 8px 0;
  text-transform: uppercase;
}

h3 {
  font: normal normal 300 20px/24px var(--roboto, sans-serif);
  margin: 8px 0;
}

h1,
h2,
h3 {
  color: var(--color-title, $color-title);
}

a {
  color: var(--color-title) !important;
  text-decoration: none;
}

p {
  text-align: left;
  &.light {
    color: var(--color-title, $color-title);
  }
  &.bright {
    color: var(--ion-color-light);
  }
}
div.light {
  // color: var(--color-text);
  // background-color: var(--ion-background-color);
}

//lists
ol[unstyled],
ul[unstyled],
ol.list-unstyled,
ul.list-unstyled {
  list-style: none;
}

.no-select, 
.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

[hidden],
.hidden {
  display: none !important;
}

[hidden-xs] {
  display: none;
}

[block] {
  display: block;
}
[italic],
.italic {
  font-style: italic;
}

[text-center] p {
  text-align: center;
}

.round {
  border-radius: 50%;
}

.bold {
  font-weight: bold;
}
.not-bold {
  font-weight: 300;
}

//TODO: move this to component
.ng2-tag-input__text-input {
  color: var(--ion-color-dark);
}

//cards
.overlay {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

//form
ion-label {
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
  --padding-start: 15px;
  --padding-end: 15px;
}

ion-input,
ion-select {
  --padding-start: 15px;
  --padding-end: 15px;
  // --color: #{$color-title}; // var(--color-title);
  color: #{$color-title}; // var(--color-title);
  border: 1px solid var(--ion-color-medium-tint);
  border-radius: 19px;
  --border-radius: 19px;

  input {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}



ion-input,
ion-textarea,
ion-select {
  color: #{$color-title}; // var(--color-title);
  
  .label-text-wrapper {
    // due to ionic7 labels
    margin-left: 16px;
  }
  .native-wrapper,
  .native-textarea {
    color: var(--ion-color-light);
  }
  .textarea-bottom,
  .input-bottom {
    margin: 6px 16px;
    .helper-text {
      color: var(--ion-color-light-shade) !important;
    }
  }

  .light & {
    .native-wrapper,
    .native-textarea {
      color: var(--ion-color-dark);
    }
    .textarea-bottom,
    .input-bottom {
      .helper-text {
        color: var(--ion-color-dark-shade) !important;
      }
    }
  }
}

// 2023-08-17 why did we need this.. ionic 7?
ion-select::part(container) {
  flex-basis: 100%;
}

// https://github.com/ionic-team/ionic-framework/issues/29503
// https://forum.ionicframework.com/t/ionic-change-causes-css-to-stop-working-any-way-to-modify-shadow-dom-to-fix-it/238400  

ion-select {
  width: 100%;
  max-width: 100%;
  border-radius: 19px;
  background-color: #2f2f2f;
  border: none;
  // --color: #{$color-title}; // var(--color-title);
  color: #{$color-title}; // var(--color-title);

  --max-height: 80%;

  &::part(container) {
    flex-basis: 100%;
  }

  .light & {
    background-color: transparent;
    border: 1px solid var(--color);
    --placeholder-color: var(--ion-color-dark);

    &::part(placeholder) {
      opacity: 1;
    }
    /* Set the text color */
    &::part(text) {
      color: var(--color-title);
    }
    /* Set the icon color and opacity */
    &::part(icon) {
      color: var(--ion-color-primary);
      opacity: 1;
      // why is this here?
      transform: translate3d(0px, 0px, 0px);
    }
  }

}


ion-textarea {
  border: 1px solid var(--ion-color-medium-tint);
  border-radius: 19px;

  textarea {
    padding-left: 15px !important;
    padding-right: 15px !important;
    min-height: 64px;
  }
}

ion-radio-group {
  &.spaced {
    ion-item {
      --padding-start: 36px;
      padding: 8px 0;
    }
  }
  ion-radio.radio-label-placement-end {
    .radio-wrapper {
      justify-content: flex-end !important;
    }
  }
}

ion-menu,
ion-menu > ion-content {
  --background: var(--transparent);
}

ion-item {
  --background: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: var(--transparent); // TODO: this might be what's killing the draggable!
}

ion-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

ion-toolbar.projects-menu-toolbar {
  --indicator-color: var(--ion-color-primary);
  ion-segment.user-menu-projects {
    padding: 0 16px;
    width: 100%;
    border-radius: 0;
    --indicator-color: var(--ion-color-primary);
    --background-checked: var(--ion-color-primary);
    --color-checked: var(--ion-color-light);
    --ion-color-contrast: var(--ion-color-primary) !important;

    ion-segment-button {
      /* iOS styles */
      &.ios {
        --color: var(--ion-color-primary);
        --color-checked: var(--ion-color-light);
        --border-radius: 20px;
        --indicator-color: var(--ion-color-primary);
        --background-checked: none;
        --color-checked: var(--ion-color-light);
        // for the selected background
        // --ion-color-contrast: var(--ion-color-medium);
      }
      // text 
      &::part(native) {
        color: var(--ion-color-primary);
      }
      &.segment-button-checked.ios::part(native) {
        color: var(--ion-color-light);
      }
      &::part(indicator-background) {
        --ion-color-contrast: var(--ion-color-medium);
      }
      /* Material Design styles - UNUSED */
      &.md {
        --color: var(--ion-color-dark);
        --color-checked: var(--ion-color-primary);
        --indicator-height: 4px;
      }
    }
    ion-label {
      text-transform: uppercase;
      padding-left: 3px;
      padding-right: 3px;
      --padding-start: 3px;
      --padding-end: 3px;
    }
  }
}


ion-popover {
  &::part(content) {
    --width: 200px; // "Approve Stack" from StackSlide is longest
  }
  ion-select-popover {
    --width: 200px;
    overflow-y: scroll !important;
  }
  &.wide {
    --width: 320px;
  }
  ion-label {
    font-size: 16px !important;
  }
  &.select-with-headings {
    .select-interface-option {
      --color: var(--color-title);
      --color-hover: var(--ion-color-primary);
    }
    .select-interface-option.item-radio-checked,
    .select-interface-option[aria-checked=true] {
      --color: var(--ion-color-primary);
      --color-hover: var(--ion-color-primary);
    }
    .select-interface-option.select-heading {
      ion-label {
        color: var(--color-title);
        font-size: 18px;
        font-weight: 700;
      }
      &.not-bold ion-label {
        font-weight: 300;
      }
    }
  }
}

ion-popover.form-select {
  max-height: 70vh; // large lists of projects flow off the screen (publish-stack & onboarding-invite)
}

ion-backdrop {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
}
// MVP-764 make it lighter
ion-popover.tutorial-tour {
  ion-backdrop {
    --backdrop-opacity: 0.5;
    background: #333333; // rgb(51, 51, 51)
  }
  &::part(content) {
    overflow: visible;
    border-bottom: 5px solid var(--background);

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -15px;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-top: 15px solid var(--background);
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
    }
  }
}

ion-popover.billing-status-options {
  --background: rgba(40, 173, 218, 0.6);
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: 300px;

  ion-content {
    --background: rgba(40, 173, 218, 0.6);
  }
}

ion-popover.user-list-popover {
  --width: 240px;
  --background: var(--ion-color-light);
  color: var(--ion-color-tertiary);
  // &::part(content) {}
}

ion-toast {
  --background: #333;
  --color: #e9eade;
  --border-color: rgba(228, 229, 214, 0.3);
  --border-width: 1px;
  --border-style: solid;
}

.light {
  ion-toolbar.top-navbar {
    --color-background: var(--color-background-light);
    --background: var(--color-background-light);
    --ion-toolbar-color:  var(--color-background-light)
    --ion-toolbar-background: var(--color-background-light);
    --ion-toolbar-border-color: var(--color-background-light);
  }
}

// make the topnav title left and handle overflow ellipsis
@media screen and (max-width: 767px) {
  ion-toolbar.top-navbar {
    ion-title {
      left: -32px;
      width: 100%;
      width: calc(100% + 12px);

      a.top-navbar__drawer {
        position: relative;
        display: block;
        text-align: left;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 32px;

        ion-icon {
          margin-bottom: -3px;
          position: absolute;
          right: 16px;  
        }
      }
    }
  }
}

ion-searchbar {
  --background: var(--ion-background-color) !important;
}

// can't get this to work??
// ref: https://github.com/ionic-team/ionic/issues/16446
// ion-modal,
// ion-popover {
//   --backdrop-opacity: 0.75;
//   ion-backdrop.sc-ion-popover-ios {
//     --backdrop-opacity: 0.75;
//       // visibility: visible;
//       opacity: 0.75;
//       // z-index: 0;
//       // background: #000;
//   }
// }

.fullheight-modal {
  --height: 100%;
  .modal-wrapper {
    height: 100%;
    top: 0%;
    position: absolute;
    display: block;
  }
}
.fullheight-iframe-modal {
  --height: 100%;

  .modal-wrapper {
    height: 100%;
    top: 0%;
    position: absolute;
    display: block;
  }
  .header {
    height: 46px;
  }

  ion-content > .container {
    // height of modal-header = 88 + 5vp
    height: calc(100% - 98px);
    .row {
      height: 100%;
    }
  }
}

.iframe-container {
  height: 100%; 
  overflow: hidden;
}

.page.container {
  // height of header + footer = 70 + 155
  height: calc(100% - 225px);
}

/* bigger sized modals for desktop */
.modal-lg {
  --width: 800px;
}

.modal-wrapper,
.popover-wrapper {
  --ion-background-color: #1a1a1a;

  .light & {
    --ion-background-color: white;
  }
}

ion-popover.social-sharing-modal,
ion-modal.social-sharing-modal {
  --width: 360px;
  --height: 420px;
  --ion-background-color: var(--ion-color-light);
}

/* targeting only portrait orientation */
@media (orientation: portrait) {
  /* CSS to apply when orientation is portrait goes here */
}

/* targeting only landscape orientation */
@media (orientation: landscape) {
  /* CSS to apply when orientation is landscape goes here */
}

@media (min-width: 0px) and (max-width: 575px) {
  /* Hide all elements with the .hide-xs-only class when the minimum width is 0px (xs breakpoint) and the maximum width is 575px (right before sm breakpoint) */
  .show-xs-only {
    display: block;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  /* Hide all elements with the .hide-sm-only class when the minimum width is 576px (sm breakpoint) and the maximum width is 767px (right before md breakpoint) */
  .show-sm-only {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Hide all elements with the .hide-md-only class when the minimum width is 768px (md breakpoint) and the maximum width is 991px (right before lg breakpoint) */
  .show-md-only {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Hide all elements with the .hide-lg-only class when the minimum width is 992px (lg breakpoint) and the maximum width is 1199px (right before xl breakpoint) */
  .show-lg-only {
    display: block;
  }
}

// popover class needs to be defined globally
.tutorial-tour {
  --background: #bf3ba1;
  --backdrop-opacity: 0.3;
  --offset-y: -24px;

  ion-content {
    --background: #bf3ba1;
  }
}

ion-alert.alert-update-available {
  h2.alert-title {
    color: var(--ion-color-secondary);
    font-size: 22px;
  }
  .alert-message {
    font-size: 16px;
  }
}

ion-alert {
  h2 {
    line-height: 1.2em;
  }
  h2.alert-sub-title {
    font-size: 16px !important;
    line-height: 20px;
    text-transform: none;
  }

  &.confirm-alert,
  &.alert-confirm-delete {
    .alert-message {
      font-size: 16px;
    }
    .alert-button-warning {
      color: var(--ion-color-secondary);
    }
  }
}

ion-button {
  --border-radius: 30px;
}

ion-button.round,
ion-button[size='default'].round {
  --padding-start: 8px;
  --padding-end: 8px;
}

ion-button[size='small'].round {
  --padding-start: 2px;
  --padding-end: 2px;
}
ion-button[color='light'] {
  --ion-color-contrast: var(--ion-color-primary) !important;

  &.large {
    --padding-start: 36px;
    --padding-end: 36px;
  }
}

ion-button.text-btn,
.text-btn ion-button {
  text-transform: capitalize !important;
  font-weight: normal !important;
  --padding-start: 0 !important;
  --padding-end: 0px !important;
}

div[scrollx='true'],
div[scrolly='true'] {
  position: relative;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
}

div[scrollx='true'] {
  overflow-x: auto;
}

div[scrolly='true'] {
  overflow-y: auto;
}

.limit-width {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.diet-limit-width {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.skinny-limit-width {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.btn-link-inline {
  margin: 0;
  padding: 0;
  height: 100%; //auto
  text-transform: none;
  font-size: inherit;
  font-weight: 700;
  margin-top: -10px; // TODO: figure this out
  line-height: normal;
  --background: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --color: var(--ion-color-primary);
  --color-activated: var(--ion-color-secondary);
  --color-focused: var(--ion-color-tertiary);

  --border-radius: 0px;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --transition: color, opacity 100ms linear;

  &.with-icon {
    --padding-end: 3px;
  }
}
ion-button.button-inline-text {
  margin: 0;
  margin-top: -8px;
  height: 18px;
  font-size: 16px;
  font-weight: 300;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.ion-text-center p {
  text-align: center !important;
}

.margin-center {
  margin: 0 auto !important;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.nowrap {
  white-space: nowrap;
}

.float-right {
  float: right;
}

.toolbar-title__logo {
  padding: 0;
  margin: 0;

  img {
    max-height: 32px;
  }

  &:focus {
    outline: none;
  }
}

form > fs-form-input {
  display: block;
  margin-bottom: 16px;
}

fs-form-input.stack-form__form__title ion-item.fs-form-item {
  margin-bottom: 8px; // tight fitting fs-input within form
}

ion-toast.gdpr-notice {
  --background: #{$color-title}; // var(--color-title);
  --color: var(--ion-text-color);
  --border-radius: 0;
  --start: 0;
  --end: 0;
  --box-shadow: none;
  --button-color: var(--ion-color-primary);
}

ion-fab {
  &.tab-fab {
    left: 12px;

    @media (max-width: 600px) {
      // for smaller viewports we need to layout the fab button above the tab bar
      position: fixed;
      left: auto;
      bottom: 80px;
      right: 14px;
    }
  }
  &.main-fab {
    bottom: 80px;
    right: 14px;

    @media screen and (min-width: 600px) {
      // bottom: 12px; // this is now overlayed by the floating tab bar @todo: fix
      bottom: 80px;
    }
  }
 
  ion-fab-list {
    right: 0;
    align-items: flex-end;

    ion-chip {
      background: var(--ion-color-primary);
      color: var(--color-title);

      &.warning {
        background: var(--ion-color-danger);
      }

      ion-label {
        white-space: nowrap;
        font-weight: bold;
      }

      &:hover {
        background: var(--ion-color-primary-shade);
      }
    }
  }
}

// when the body is-fullscreen, we do not want to see the floating-fab
.is-fullscreen,
.is-plyr-fullscreen {
  .floating-fab {
    display: none !important;
  }
}

// ion-fab.main-fab {
//   bottom: 80px;
//   right: 14px;

//   @media screen and (min-width: 600px) {
//     // bottom: 12px; // this is now overlayed by the floating tab bar @todo: fix
//     bottom: 80px;
//   }
//   ion-fab-list {
//     right: 0;
//     align-items: flex-end;
//     ion-chip {
//       background: var(--ion-color-primary);
//       color: var(--color-title);
//       &.warning {
//         background: var(--ion-color-danger);
//       }
//       ion-label {
//         white-space: nowrap;
//       }
//       &:hover {
//         background: var(--ion-color-primary-shade);
//       }
//     }
//   }
// }

fs-list-title {
  &.no-top-margin {
    h2.list-title {
      margin-top: 0;
    }
  }
  &.left-margin {
    h2.list-title {
      margin-left: 16px;
    }
  }
  &.dark {
    background: var(--color-background)
  }
}

ion-menu.menu-side-start ion-content {
  --background: transparent;
  border-top-right-radius: 80px;
}

.crew-section,
.member-section,
.project-member-section {
  background: var(--ion-color-light);
  --background: var(--ion-color-light);
  --color: var(--ion-color-tertiary);
  padding-top: 48px;
  padding-bottom: 48px;
  margin-top: 48px;
  margin-bottom: 48px;

  h1,
  h2,
  h3 {
    color: var(--ion-color-tertiary);
  }
  .carousel__vertical-text {
    color: var(--ion-color-tertiary) !important;
  }
}

.picker-columns .picker-above-highlight {
  background: linear-gradient(180deg, rgba(51, 51, 51, 0.9) 40%, rgba(51, 51, 51, 0.4));
}
.picker-columns .picker-below-highlight {
  background: linear-gradient(0deg, rgba(51, 51, 51, 0.9) 70%, rgba(51, 51, 51, 0.1));
}
.picker-opts {
  color: #fff;
}

.tour-slides .swiper-pagination {
  bottom: 44px !important;
  width: 300px;
  left: calc(50% - 150px);
}
@media (max-width: 600px) {
  .tour-slides .swiper-pagination {
    max-width: 50%;
    width: 50%;
    left: 25%;
  }
}

/* targeting only portrait orientation */
@media (orientation: portrait) {
  /* CSS to apply when orientation is portrait goes here */
}

/* targeting only landscape orientation */
@media (orientation: landscape) {
  /* CSS to apply when orientation is landscape goes here */
}

@media (min-width: 441px) {
  /* Hide all elements with the .hide-xs-up class when the minimum width is 0px (xs breakpoint) */
  .hide-xs-up {
    display: none;
  }

  /* Show all elements with the .show-xs-up class when the minimum width is 0px (xs breakpoint) */
  .show-xs-up {
    display: block;
  }
}

@media (min-width: 576px) {
  /* Hide all elements with the .hide-sm-up class when the minimum width is 576px (sm breakpoint) */
  .hide-sm-up {
    display: none;
  }

  /* Show all elements with the .show-sm-up class when the minimum width is 576px (sm breakpoint) */
  .show-sm-up {
    display: block;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  /* Hide all elements with the .hide-xs-only class when the minimum width is 0px (xs breakpoint) and the maximum width is 575px (right before sm breakpoint) */
  .show-xs-only {
    display: block;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  /* Hide all elements with the .hide-sm-only class when the minimum width is 576px (sm breakpoint) and the maximum width is 767px (right before md breakpoint) */
  .show-sm-only {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Hide all elements with the .hide-md-only class when the minimum width is 768px (md breakpoint) and the maximum width is 991px (right before lg breakpoint) */
  .show-md-only {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Hide all elements with the .hide-lg-only class when the minimum width is 992px (lg breakpoint) and the maximum width is 1199px (right before xl breakpoint) */
  .show-lg-only {
    display: block;
  }
}

// tooltips
popper-content {
  .ngxp__container {
    color: $color-title;
    background-color: $color-text;
    font-size: 15px;
    padding: 20px;
    border-radius: 10px;
  }
}

.plyr {
  width: 100%;
  height: 100%;
}

.plyr-el.transitioning {
  .plyr__control,
  .plyr__controls {
    display: none !important;
  }
}

.tab-spacer,
.tabs-spacer,
.tab-bottom-spacer {
  height: 200px;
  width: 100%;

  @media (min-width: 320px) {
    height: 160px;
  }
  @media (min-width: 488px) {
    height: 120px;
  }
  @media (min-width: 576px) {
    height: 80px;
  }
}
.no-bottom-spacer .tab-bottom-spacer  {
  height: 0;
}

/* filestack */

/*
fix: notification not showing on a scrollable page
https://github.com/filestack/filestack-js/issues/335
maxSize doesn't provide a user error message
https://github.com/filestack/filestack-js/issues/484
*/
.fsp-notifications__container {
  position: fixed !important;
}

/* change picker appearance  */
.fsp-picker {
  background-color: rgba(47, 53, 62, 0.86) !important;
}

/* customize picker modal  */
.fsp-picker .fsp-modal {
  // background: #fff;
  border-radius: 0;
  border: none;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.fsp-picker .fsp-header {
  color: rgba(0, 0, 0, 0.54);
}

.fsp-picker .fsp-url-source__input,
.fsp-picker .fsp-url-source__submit-button {
  border: none;
  border-radius: 0;
  height: 30px;
  border-bottom: 1px solid var(--ion-color-primary);
}

.fsp-picker .fsp-url-source__submit-button {
  background-color: var(--ion-color-primary);
}

.fsp-picker .fsp-url-source__input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid var(--ion-color-primary);
}

.fsp-picker .fsp-url-source__submit-button {
  width: 30px;
  border: 1px solid var(--ion-color-primary);
  padding: 0;
}

/* update styles for drop area */
.fsp-picker .fsp-drop-area {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 0;
}

.fsp-button--auth,
.fsp-button--primary {
  color: var(--ion-color-light) !important;
  background-color: var(--ion-color-primary) !important;
}

// disable icon hover shows icon name
ion-icon.no-pointer-events,
ion-button ion-icon,
ion-fab-button ion-icon {
  pointer-events: none;
}

/** Hide Tabbar on Landscape */
.orientation-landscape {
  .player-page {
    // .panel-action, (storylines) likely not needed
    ion-tab-bar.floating-tabs {
      display: none !important;
    }
  }
}

body.is-fullscreen,
body.is-plyr-fullscreen {
  ion-tab-bar.floating-tabs {
    display: none;
  }
}
// this didn't work as hoped... leaving since it's interesting..
// @media all and (display-mode: fullscreen) {
//   ion-tab-bar.floating-tabs {
//     display: none;
//   }
//   body.is-fullscreen,
//   body.is-plyr-fullscreen {
//
//   }
// }

.search-toolbar {
  // max-width: 75%;
  margin-right: 86px; 
  margin-left: -12px;
  margin-bottom: 8px;
  @media only screen and (min-width : 469px) {
    margin-left: 12px;
  }
}
.accordion-search-toolbar {
  max-height: 0;
  transition: max-height ease-in-out 0.5s;
  overflow: hidden;

  &.isShowing {
    max-height: 72px;
  }
}
// for the clips list accordion
ion-accordion {
  ion-item.accordion-main-header {
    ion-label {
      // padding-left: 0;
      h2.list-title {
        margin-top: 0;
        font-size: 24px;
        font-weight: 600;
      }
    }
    ion-icon {
      color: var(--ion-color-primary);
    }
    &.no-padding-start {
      --padding-start: 0;
      ion-label {
        padding-left: 0;
      }
    }
    &.studio-list {
      ion-item {
        align-items: start;
      }
      h3.clips-subtitle {
        text-transform: none;
        font: normal normal 300 20px/24px var(--roboto, sans-serif);
        margin-top: 24px;
      }
    }
  }
  .accordion-search-toolbar {
    margin-bottom: -12px;
    margin-top: -12px;
    max-height: 24px;
    transition: max-height linear 0.5s;
    
    &.isShowing {
      max-height: 72px;
    }
  }
}

// carousel list spacing
.list-wrap.list-spacing {
  padding-bottom: 32px !important;
}

// overwrite Featured Stacks to be bigger than Recent or others
.list-featured-stacks .carousel {
  &__container {
    margin-bottom: 44px !important;
  }
  // list-spacing
  fs-slide {
    min-width: 300px !important;
    min-width: 90vw !important;
    .stack-slide {
      max-width: 300px !important;
      max-width: 84vw !important;
    }
    @media (min-width: 488px) {
      min-width: 76vw !important;
    }
    @media (min-width: 600px) {
      min-width: 66vw !important;
    }
    @media (min-width: 768px) {
      min-width: 53vw !important;
    }
    @media (min-width: 880px) {
      min-width: 43vw !important;
    }
    @media (min-width: 1024px) {
      min-width: 33vw !important;
    }
  }
}

ion-note.well {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 30px;
  background-color: #333333;
  background-color: rgba(var(--ion-color-dark-rgb), 0.25);
  
  &.ion-color-medium {
    background-color: rgba(var(--ion-color-medium-rgb), 0.25);
    color: var(--ion-color-light);
  }
}

.error {
  color: var(--ion-color-danger);
}
.warning {
  color: var(--ion-color-warning);
}
.orange {
  color: var(--ion-color-secondary);
}

ion-datetime-button {
  &::part(native) {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-light);
  }
}

.subscription {
  &-color {
    // color: #e9eade;
    color: var(--color-title, $color-title);
    --ion-text-color: var(--color-title, $color-title);
    --ion-text-color-rgb: var(--color-title-rgb);
    &.light {
      color: var(--ion-color-light);
    }
  }
  
  &-chip {
    padding: 3px 10px;
    border-radius: 9px;
    font-weight: 300;
    background-color: rgba(41, 41, 41, 0.5);
    --ion-background-color: rgba(41, 41, 41, 0.5);
    --ion-background-color-rgb: rgb(41, 41, 41);
  }
  &-btn {

  }
  &.light {
    color: var(--ion-color-light);
  }
}

// https://ionicframework.com/docs/angular/slides#additional-ion-slides-styles
swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.light {
  .project__hero__content {
    --color-title: var(--ion-color-light);
    color: var(--ion-color-light);
    h1, h2, h3 {
      color: var(--ion-color-light);
    }
  }
  ion-searchbar {
    --background: var(--ion-color-light);
    --color: var(--ion-color-dark);
    --placeholder-color: var(--ion-color-dark);
    --border-color: var(--ion-color-light);
    color: var(--ion-color-tertiary) !important;

    .searchbar-input {
      color: var(--ion-color-dark);
      background: var(--ion-color-light) !important;
    }
  }
  .carousel__vertical-text {
    color: var(--ion-color-tertiary) !important;
  }
}
