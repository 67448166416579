// SCSS variables - should not be used outside of this file, use css variables instead
$color-title: #e9eade;
$color-title-rgb: 233, 234, 222;
$color-text: #8b897f;
$color-text-rgb: 139, 137, 127;
$color-background: #333333;
$color-background-rgb: 51, 51, 51;
$font: 'Roboto', 'Gill Sans', sans-serif;

$split-layout-color-background: #f6f6f2;
$split-layout-color-text-left: #{$color-title};

:root {
  --raleway: 'Raleway', Helvetica, sans-serif;
  --roboto: #{$font};

  // START GENERATED COLORS (https://ionicframework.com/docs/theming/color-generator)
  --ion-color-primary: #66ad9e;
  --ion-color-primary-rgb: 102, 173, 158;
  --ion-color-primary-contrast: #e9eade; // #{$color-title}
  --ion-color-primary-contrast-rgb: 233, 234, 222;
  --ion-color-primary-shade: #5a988b;
  --ion-color-primary-tint: #75b5a8;

  --ion-color-secondary: #f37941;
  --ion-color-secondary-rgb: 243, 121, 65;
  --ion-color-secondary-contrast: #111111;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d66a39;
  --ion-color-secondary-tint: #f48654;

  --ion-color-tertiary: #8b897f; // $color-text
  --ion-color-tertiary-rgb: 139, 137, 127;
  --ion-color-tertiary-contrast: #111111;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #7a7970;
  --ion-color-tertiary-tint: #97958c;

  --ion-color-success: #0a9340;
  --ion-color-success-rgb: 10, 147, 64;
  --ion-color-success-contrast: #eeeeee;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #098138;
  --ion-color-success-tint: #239e53;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #eeeeee;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #eeeeee;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #333333;
  --ion-color-dark-rgb: 51, 51, 51;
  --ion-color-dark-contrast: #eeeeee;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2d2d2d;
  --ion-color-dark-tint: #474747;

  --ion-color-medium: #8A897F;
  --ion-color-medium-rgb: 138,137,127;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #797970;
  --ion-color-medium-tint: #96958c;

  --ion-color-light: #fafaf7;
  --ion-color-light-rgb: 250, 250, 247;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcdcd9;
  --ion-color-light-tint: #fbfbf8;
  // END GENERATED COLORS

  --ion-color-offwhite: #e4e5d6;
  --ion-color-offwhite-rgb: 228, 229, 214;
  --ion-color-offwhite-contrast: #111111;
  --ion-color-offwhite-contrast-rgb: 0, 0, 0;
  --ion-color-offwhite-shade: #e4e5d6;
  --ion-color-offwhite-tint: #e4e5d6;

  // text and background colors to generate stepped colors
  --ion-background-color: #{$color-background};
  --ion-background-color-rgb: #{$color-background-rgb};
  --ion-text-color: #{$color-text};
  --ion-text-color-rgb: #{$color-text-rgb};

  // START GENERATED STEPPED COLORS (https://ionicframework.com/docs/theming/themes#stepped-colors)
  // used color values:
  // - background: $color-background
  // - text: $color-text
  --ion-color-step-50: #373737;
  --ion-color-step-100: #3c3c3b;
  --ion-color-step-150: #40403e;
  --ion-color-step-200: #454442;
  --ion-color-step-250: #494946;
  --ion-color-step-300: #4d4d4a;
  --ion-color-step-350: #52514e;
  --ion-color-step-400: #565551;
  --ion-color-step-450: #5b5a55;
  --ion-color-step-500: #5f5e59;
  --ion-color-step-550: #63625d;
  --ion-color-step-600: #686761;
  --ion-color-step-650: #6c6b64;
  --ion-color-step-700: #716f68;
  --ion-color-step-750: #75746c;
  --ion-color-step-800: #797870;
  --ion-color-step-850: #7e7c74;
  --ion-color-step-900: #828077;
  --ion-color-step-950: #87857b;
  // END GENERATED STEPPED COLORS

  // font
  --ion-font-family: #{$font};

  // used for h1, h2, h3
  --color-title: #{$color-title};
  --color-title-rgb: #{$color-title-rgb};  
  --color-text: #{$color-text};
  // light text on dark background
  --color-dark-contrast: #{$color-title};
  --color-background: #{$color-background};
  --color-background-light: #{$color-title};
  --color-background-dark: #{$color-background};

  --color-split-layout-background: #{$split-layout-color-background};
  --color-split-layout-text-left: #{$split-layout-color-text-left};

  --background-tabbar: rgb(41, 41, 41);

  // add "light" to body class or even for certain pages/modals for them to switch to light mode
  .light {
    --color-title: #{$color-text};
    --color-text: #{$color-text};
    --ion-text-color: #{$color-text};
    --ion-text-color-rgb: #{$color-text-rgb};
    --ion-background-color: #{$color-title};
    --ion-background-color-rgb: #{$color-title-rgb};
    --color-background: #{$color-title};
  }
  .dark {
    --color-title: #{$color-title};
    --color-text: #{$color-text};
    --ion-text-color: #{$color-text};
    --ion-text-color-rgb: #{$color-text-rgb};
    --ion-background-color: #{$color-background};
    --ion-background-color-rgb: #{$color-background-rgb};
    --color-background: #{$color-background};
  }

}

@media (prefers-color-scheme: dark) {
  :root {
    /* global app variables */
  }

  :root.ios {
    /* global ios app variables */
  }

  :root.md {
    /* global md app variables */
  }
}
